import { useMemo, useState, useEffect, useCallback, useRef } from "react"

import dayjs, { type Dayjs } from "dayjs"
import { useGetSalesMutation } from "../../../redux/business/business.api"

import { useMediaQuery, useTheme } from "@mui/material"

import Alert, { type AlertColor } from "@mui/material/Alert"
import { LocationInputType, type HandleSnackBarType } from "../../../types/reportTypes"
import { ReportLayout } from "../../../layouts/ReportLayout"
import { ReportSkeleton } from "../../../layouts/Skeletons/ReportSkeleton"
import { getReportsHandler } from "../../../utils/ReportHandlerInput"
import { flattenSales } from "../../../middlewares/flattenSales"
import type { Business, FlattenedSalesReport, SalesStore } from "../../../types/ISalesReportTypes"
import { SalesReport } from "../../../components/Reports/SalesReport/SalesReport"
import { CalendarInputType } from "../../../types/reportTypes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { saveSalesData } from "../../../redux/business/business.slice"
import { salesDetailedConfig, salesSummaryConfig } from "../../../data/exportConfigs"
import { flattenExport } from "../../../utils/flattenExport"
import { type RootState } from "../../../redux/store"
import { hideHeader } from "../../../redux/filters/hideHeader.slice"

interface Props {
  openFiltersHandler?: () => void
}

export function Sales(props: Props) {
  const [fetchReport] = useGetSalesMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = true
  const showTotalSummaries = true
  const requiresAPICall = true
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const [report, setReport] = useState<Business | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const allLocationsOption = { name: "All Locations", id: null, businessId: null }
  const [selectedValue, setSelectedValue] = useState<any>(allLocationsOption)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const accordionHeadings = isSmallScreen
    ? ["Name", "Sales", "Free", "Promo"]
    : ["Name", "Sales", "Free", "Promo", "%"]
  const [firstSelectedValue, setFirstSelectedValue] = useState<any>(null)
  const isMountedRef = useRef(true)
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
      dispatch(hideHeader(false))
    }
  }, [])

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
    if (firstSelectedValue === null) {
      setFirstSelectedValue(value)
    }
  }

  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  const flattenedReport = useMemo(() => {
    if (report != null) {
      return flattenSales({
        salesData: report,
      })
    }
    return null
  }, [report])

  useEffect(() => {
    const handleOrientationChange = () => {
      // Explicitly check if flattenedReport and its stores property are not null or undefined.
      if (
        flattenedReport !== null &&
        flattenedReport !== undefined &&
        Object.keys(flattenedReport?.stores ?? {}).length > 0
      ) {
        if (Object.keys(flattenedReport.stores).length > 0) {
          setShowForm(false)
        }
      }
    }

    window.addEventListener("orientationchange", handleOrientationChange)

    // Cleanup the event listener when the component unmounts or the flattenedReport changes.
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [flattenedReport?.stores])

  useEffect(() => {
    if (
      isMobile &&
      flattenedReport != null &&
      flattenedReport.stores != null &&
      Object.keys(flattenedReport.stores).length !== 0
    ) {
      toggleForm(false)
    }
  }, [report, isMobile, flattenedReport?.stores, toggleForm])

  // this useEffect allows us to navigate through browser back and forward button
  // uncomment the code below for browser back and forward button

  useEffect(() => {
    if (!searchParams.has("locationId")) {
      return
    }
    const startDateParam = searchParams.get("startDate")
    const endDateParam = searchParams.get("endDate")

    const startDate =
      startDateParam !== null && startDateParam !== ""
        ? dayjs(startDateParam)
        : dayjs().startOf("day")
    const endDate =
      endDateParam !== null && endDateParam !== "" ? dayjs(endDateParam) : dayjs().endOf("day")

    // Fetch the locationId from URL search parameters
    const locationId = searchParams.get("locationId") ?? ""
    const locationName = searchParams.get("locationName")
    const businessId = searchParams.get("businessId")

    // Determine the selected value based on the presence of locationId
    const newSelectedValue =
      locationId === ""
        ? allLocationsOption
        : {
            name: locationName,
            id: locationId,
            businessId,
          }

    setLocation(newSelectedValue.id)
    setSelectedValue(newSelectedValue)
    setFirstSelectedValue(newSelectedValue)
    setReportDateRange([startDate, endDate])
    setHeaderDateRange([startDate, endDate])
    getReportsHandler(
      fetchReport,
      setIsFetchingReport,
      handleSnackBar,
      setReport,
      newSelectedValue.id,
      hasHeaderDate,
      requiresAPICall,
      setShowHeaderDate,
      "data.data",
      {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        businessId: newSelectedValue.businessId,
      },
      isMountedRef
    ).catch((error) => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    if ((flattenedReport !== null) !== undefined) {
      dispatch(saveSalesData(flattenedReport))
    }
  }, [flattenedReport])

  useEffect(() => {
    if (flattenedReport != null) {
      const filteredReport = filterBusinesses(flattenedReport)
      dispatch(saveSalesData(filteredReport))
    }
  }, [flattenedReport])

  const productFilters = useSelector((state: RootState) => state.filters.productFilters)
  const statusFilters = useSelector((state: RootState) => state.filters.statusFilters)

  const filterStores = (stores: SalesStore[]): SalesStore[] => {
    const noProductFiltersSelected = Object.values(productFilters).every((value) => !value)
    const noStatusFiltersSelected = Object.values(statusFilters).every((value) => !value)

    if (noProductFiltersSelected && noStatusFiltersSelected) {
      return stores
    }

    return stores.filter((store) => {
      // Product Filters
      const productFilterKey = Object.keys(productFilters).find(
        (key) => key.toLowerCase() === store.store.productType.toLowerCase()
      )

      let productFilterValue = true
      if (productFilterKey !== undefined) {
        productFilterValue = productFilters[productFilterKey]
      }

      // Status Filters
      const statusFilterKey = Object.keys(statusFilters).find(
        (key) => key.toLowerCase() === store.store.statusMsg.toLowerCase()
      )

      let statusFilterValue = true
      if (statusFilterKey !== undefined) {
        statusFilterValue = statusFilters[statusFilterKey]
      }

      const productFilterPass = noProductFiltersSelected || productFilterValue
      const statusFilterPass = noStatusFiltersSelected || statusFilterValue

      return productFilterPass && statusFilterPass
    })
  }

  const filterBusinesses = (businesses: FlattenedSalesReport): FlattenedSalesReport => {
    const filteredBusinesses: FlattenedSalesReport = {
      stores: {},
      totalSummary: { s: 0, f: 0, p: 0, percentage: 0 },
    }
    console.log("flattenedSales:", businesses)

    for (const [businessId, businessData] of Object.entries(businesses.stores)) {
      const filteredStores = filterStores(businessData.stores)
      const summary = filteredStores.reduce(
        (prev, store) => {
          return {
            s: prev.s + store.summary.s,
            f: prev.f + store.summary.f,
            p: prev.p + store.summary.p,
            percentage: 0, // Initialize percentage to avoid TypeScript error
          }
        },
        { s: 0, f: 0, p: 0, percentage: 0 }
      )

      // Calculate percentage using the aggregated values
      if (summary.s > 0) {
        summary.percentage = ((summary.f + summary.p) / summary.s) * 100
      } else {
        summary.percentage = 0 // Handle case where `s` is 0 to avoid division by zero
      }

      // Update overall total summary
      filteredBusinesses.totalSummary.s += summary.s
      filteredBusinesses.totalSummary.f += summary.f
      filteredBusinesses.totalSummary.p += summary.p

      if (filteredStores.length > 0) {
        filteredBusinesses.stores[businessId] = {
          ...businessData,
          stores: filteredStores,
          totalSummary: summary,
        }
      }
    }

    // Calculate overall percentage for total summary
    if (filteredBusinesses.totalSummary.s > 0) {
      filteredBusinesses.totalSummary.percentage =
        ((filteredBusinesses.totalSummary.f + filteredBusinesses.totalSummary.p) /
          filteredBusinesses.totalSummary.s) *
        100
    } else {
      filteredBusinesses.totalSummary.percentage = 0
    }

    // Dispatch the filtered data
    dispatch(saveSalesData(filteredBusinesses))

    return filteredBusinesses
  }

  const filteredReport =
    flattenedReport?.stores !== undefined ? filterBusinesses(flattenedReport) : null

  const shouldHideHeader =
    filteredReport === null || Object.keys(filteredReport.stores).length === 0
  dispatch(hideHeader(!shouldHideHeader))

  const exportFlattenedDetailedData =
    filteredReport?.stores != null
      ? flattenExport(Object.values(filteredReport.stores), salesDetailedConfig)
      : []

  const exportFlattenedSummaryData =
    filteredReport?.stores != null
      ? flattenExport(Object.values(filteredReport.stores), salesSummaryConfig)
      : []

  console.log("filteredReport", filteredReport)

  return (
    <ReportLayout
      hasExport={true}
      exportFlattenedDetailedData={exportFlattenedDetailedData}
      exportFlattenedSummaryData={exportFlattenedSummaryData}
      reportName={"Sales"}
      calendarInput={CalendarInputType.DateRange}
      locationInput={LocationInputType.SelectGetLoad}
      showHeaderDate={showHeaderDate}
      showTotalSummaries={showTotalSummaries}
      hasHeaderDate={hasHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={accordionHeadings}
      showAccordionHeadings={!shouldHideHeader}
      showSummaries={true}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      accordionSummaries={["Sales", "Free", "Promo", "%"]}
      getReportHandler={async () => {
        if (selectedValue.id === null) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Delete the parameters you want to clear
          newSearchParams.set("locationId", "")
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.delete("businessId")
          newSearchParams.delete("locationName")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        } else if (selectedValue.id !== null && selectedValue.id !== undefined) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Set the search params as you had before
          newSearchParams.set("locationId", selectedValue.id)
          newSearchParams.set("businessId", selectedValue.businessId)
          newSearchParams.set("locationName", selectedValue.name)
          newSearchParams.set("startDate", reportDateRange[0]?.format("YYYY-MM-DD") ?? "")
          newSearchParams.set("endDate", reportDateRange[1]?.format("YYYY-MM-DD") ?? "")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        }
        try {
          await getReportsHandler(
            fetchReport,
            setIsFetchingReport,
            handleSnackBar,
            setReport,
            location,
            hasHeaderDate,
            requiresAPICall,
            setShowHeaderDate,
            "data.data",
            {
              startDate: reportDateRange[0]?.startOf("day").format("YYYY-MM-DD"),
              endDate: reportDateRange[1]?.endOf("day").format("YYYY-MM-DD"),
              businessId: selectedValue.businessId,
            },
            isMountedRef
          )

          setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
          setFirstSelectedValue(selectedValue)
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <>
        {isFetchingReport && <ReportSkeleton />}
        {!isFetchingReport &&
          flattenedReport !== null &&
          flattenedReport.stores !== null &&
          flattenedReport.stores !== undefined &&
          Object.keys(flattenedReport.stores).length === 0 &&
          snackbarOpen && (
            <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
              {snackbarMessage}
            </Alert>
          )}
        {!isFetchingReport &&
          flattenedReport !== null &&
          flattenedReport !== undefined &&
          flattenedReport.stores !== null &&
          flattenedReport.stores !== undefined && <SalesReport report={filteredReport} />}
      </>
    </ReportLayout>
  )
}
