import React, { useState, useCallback, useEffect, useRef } from "react"
import useUserActivity from "../hooks/useUserActivity"
import InactiveSessionDialog from "./InactiveSessionDialog"
import { useUserContext } from "../context/UserContext"
import { decodeJwtTtl } from "../utils/decodeJwtTtl"
import { useDispatch } from "react-redux"
import { showInactivityToast } from "../redux/toastSlice"
import dayjs, { type Dayjs } from "dayjs"

const inactivityBuffer = 600
const countDown = 59

export const ActivityMonitoring: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [timeLeft, setTimeLeft] = useState(countDown)
  const { signOutHandler, refreshTokenHandler } = useUserContext()
  const dispatch = useDispatch()
  const token = localStorage.getItem("token") ?? ""
  const ttl = decodeJwtTtl(token)
  const inactiveTargetTimeRef = useRef<Dayjs | null>(null)

  const handleInactivity = useCallback((lastActivityTime: Dayjs) => {
    const totalAllowedInactiveTime = countDown + inactivityBuffer
    const targetTime = lastActivityTime.add(totalAllowedInactiveTime, "second")
    inactiveTargetTimeRef.current = targetTime
    const currentTime = dayjs()
    const remainingCountDown = Math.max(targetTime.diff(currentTime, "second"), 0) // Calculate remaining time in seconds

    // when inactive time elapsed but countdown is not 0 we will show the popup
    if (remainingCountDown > 0) {
      setTimeLeft(remainingCountDown)
      setDialogOpen(true)
    } else {
      signOutHandler()
      dispatch(showInactivityToast("You have been signed out due to inactivity."))
    }
  }, [])

  useUserActivity(inactivityBuffer * 1000, handleInactivity)

  useEffect(() => {
    if (!isDialogOpen) return

    // Interval to update the UI with the current time left
    const countdownInterval = setInterval(() => {
      if (inactiveTargetTimeRef.current != null) {
        const currentTime = dayjs()
        const newTimeLeft = Math.max(inactiveTargetTimeRef.current.diff(currentTime, "second"), 0) // Calculate remaining time in seconds
        setTimeLeft(newTimeLeft)

        // If time left is 0, signout user
        if (newTimeLeft <= 0) {
          setDialogOpen(false)
          signOutHandler()
          dispatch(showInactivityToast("You have been signed out due to inactivity."))
        }
      }
    }, 1000)

    return () => {
      clearInterval(countdownInterval)
    }
  }, [isDialogOpen, signOutHandler])

  const handlePrimaryAction = async () => {
    if (ttl !== null && ttl > 0) {
      if (ttl <= inactivityBuffer) {
        await refreshTokenHandler()
      }
      setDialogOpen(false)
    }
  }

  return (
    <InactiveSessionDialog
      timeLeft={timeLeft}
      totalTime={countDown}
      open={isDialogOpen}
      onPrimaryAction={() => {
        handlePrimaryAction().catch((error) => {
          console.error("Error during session renewal:", error)
        })
      }}
      onSecondaryAction={signOutHandler}
    />
  )
}
