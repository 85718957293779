import CircleIcon from "@mui/icons-material/Circle"

interface StatusCircleIconProps {
  status: string
  size: number
}

export const StatusCircleIcon = ({ status, size }: StatusCircleIconProps) => {
  const getStatusColor = () => {
    switch (status) {
      case "online":
        return "green"
      case "offline":
        return "#e70f0fde"
      case "hold":
        return "gray"
      case "closed":
        return "black"
      default:
        return "grey"
    }
  }

  const circleIconStyle = {
    color: getStatusColor(),
    fontSize: `${size}px`,
    marginRight: "4px",
  }

  return <CircleIcon style={circleIconStyle} />
}
