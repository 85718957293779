import React from "react"

import { type PeriodDataRoot, type IBusiness } from "./PeriodTypesFlattened"
import { BusinessCard } from "./PeriodBusinessCard"

export function PeriodBusinesses(report: PeriodDataRoot): React.ReactElement {
  return (
    <>
      {report.periodData.map((business: IBusiness) => {
        return (
          <React.Fragment key={business.businessInfo.businessId}>
            <BusinessCard business={business} />
          </React.Fragment>
        )
      })}
    </>
  )
}
