import { useEffect, useRef, useState } from "react"

import dayjs, { type Dayjs } from "dayjs"

const useUserActivity = (inactivityBuffer: number, onTimeout: (time: Dayjs) => void) => {
  const [isInactive, setIsInactive] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const resetTimer = () => {
    const lastActivityTime = dayjs()
    if (timerRef.current != null) {
      clearTimeout(timerRef.current)
    }
    setIsInactive(false)
    timerRef.current = setTimeout(() => {
      setIsInactive(true)
      onTimeout(lastActivityTime)
    }, inactivityBuffer)
  }

  useEffect(() => {
    const events = [
      "click",
      "keypress",
      "scroll",
      "mousemove",
      "touchstart",
      "touchmove",
      "pointerdown",
      "pointermove",
      "mouseup",
    ]
    const handleMouseDown = () => {
      if (timerRef.current != null) {
        clearTimeout(timerRef.current)
      }
      setIsInactive(false)
    }
    events.forEach((event) => {
      window.addEventListener(event, resetTimer)
    })
    window.addEventListener("mousedown", handleMouseDown)
    resetTimer()

    return () => {
      if (timerRef.current != null) {
        clearTimeout(timerRef.current)
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer)
      })
      window.removeEventListener("mousedown", handleMouseDown)
    }
  }, [inactivityBuffer, onTimeout])

  return { isInactive, resetTimer }
}
export default useUserActivity
